import React from "react";
import cricket from '../images/gamesimage/jollycricket/cricket.jpeg';  // Ensure this path is correct
import { useNavigate } from "react-router-dom";

const JollyCricket = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/Carrom'); // Replace with the path for the previous game
  }

  function handleNext() {
    navigate('/Knight'); // Replace with the path for the next game
  }

  return (
    <div className="container my-5 w-75">
         <button onClick={handlePrevious} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous Game</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsJollyCricket"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={cricket}
                    className="d-block w-100 img-fluid"
                    alt="Jolly Cricket"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Jolly Cricket</h1>
          <p>
          Get ready to experience the thrill of the cricket field like never before with Jolly Cricket!
           This engaging and fast-paced game brings the excitement of cricket to your screen,
           offering a perfect blend of strategy, skill, and fun.
          </p>
          {/* Navigation buttons outside the carousel */}
          <div className="d-flex justify-content-between mt-3">
            
          <button onClick={handleNext} className="btn btn-secondary">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JollyCricket;
