import React from 'react';
import nmslogo from '../images/nmslogo.svg';
import './Navbar.css';
import { Link as ScrollLink } from 'react-scroll';

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-custom fixed-top" style={{ fontSize: '18px' }}>
    <div className="container-xxl">
      <a href="#banner" className="navbar-brand d-flex align-items-center">
        <img src={nmslogo} alt="NMS Games Logo" className="me-2" />
        <h4 className="mb-0 text-white">NMS Games</h4>
      </a>

      {/* Navbar toggler button */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarLinks"
        aria-controls="navbarLinks"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Navbar links */}
      <div className="collapse navbar-collapse" id="navbarLinks">
        <ul className="navbar-nav ms-auto text-warning">
          <li className="nav-item">
            <ScrollLink to="banner" className="nav-link" smooth={true} duration={500} offset={-60}>
              Home
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="about" className="nav-link" smooth={true} duration={500} offset={-60}>
              About
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="features" className="nav-link" smooth={true} duration={500} offset={-60}>
              Features
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="expertise" className="nav-link" smooth={true} duration={500} offset={-60}>
              Expertise
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="services" className="nav-link" smooth={true} duration={500} offset={-60}>
              Services
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="games" className="nav-link" smooth={true} duration={500} offset={-60}>
              Games
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink to="enquiry" className="nav-link" smooth={true} duration={500} offset={-60}>
              Enquiry
            </ScrollLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default NavBar;
