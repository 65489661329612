import React from "react";
import bg5 from "../images/gamesimage/tambola/bg5.png";
import boards1 from "../images/gamesimage/tambola/boards1.png";
import { useNavigate } from "react-router-dom";

const Tambola = () => {
  const navigateFunTimer = useNavigate();
  function navigateToFunTimer() {
    navigateFunTimer("/funtarget");
  }

  const navigateLudoFantoosh = useNavigate();
  function navigateToLudoGame(){
    navigateLudoFantoosh('/ludogame');
  }


  return (
    <div className="container my-5 w-75">


      <button onClick={navigateToLudoGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={bg5}
                    className="d-block w-100 img-fluid"
                    style={{ width: "350px", height: "350px" }}
                    alt="Third slide"
                  />
                </div>

                <div className="carousel-item">
                  <img
                    src={boards1}
                    className="d-block w-100 img-fluid"
                    style={{ width: "350px", height: "350px" }}
                    alt="Third slide"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>Tambola</h1>
          <p>
            Welcome to Tambola, where the thrill of the game meets the
            excitement of winning. Our tambola offers a wide range of games and
            entertainment for all types of players. Join us for an unforgettable
            experience.
          </p>
          <button onClick={navigateToFunTimer} className="btn btn-secondary ">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tambola;
