import React from "react";
import bg9 from "../images/gamesimage/poker/bg9.png";
import poker from "../images/gamesimage/poker/poker.png";
import { useNavigate } from "react-router-dom";

const Poker = () => {
  const navigateRouletteGame = useNavigate();
  function navigateToRoulette() {
    navigateRouletteGame("/roulette");
  }

  const navigateNmsRummyGame = useNavigate();
  function navigateToNmsRummy(){
    navigateNmsRummyGame('/rummy');
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToNmsRummy} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {/* <div className="carousel-item">
                  <img src={bg2} className="d-block w-100" alt="First slide" />
                </div> */}
                <div className="carousel-item">
                  <img src={bg9} className="d-block w-100" alt="Second slide" />
                </div>

                <div className="carousel-item active">
                  <img
                    src={poker}
                    className="d-block w-100"
                    alt="Fourth slide"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Poker</h1>
          <p>
            Welcome to Poker, where the thrill of the game meets the excitement
            of winning. Our poker offers a wide range of games and entertainment
            for all types of players. Join us for an unforgettable experience.
          </p>
          <button onClick={navigateToRoulette} className="btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Poker;
