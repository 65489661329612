import React from "react";
import DetonixImage from "../images/gamesimage/Detonix/detonix.jpg"; 
import { useNavigate } from "react-router-dom";

const Detonix = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/Venturex'); // Replace with the path for the previous game
  }

  function handleNext() {
    navigate('/Cards'); // Replace with the path for the next game
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={handlePrevious} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous Game</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsDetonix"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={DetonixImage}
                    className="d-block w-100 img-fluid"
                    style={{ width: '350px', height: '350px' }} 
                    alt="Detonix"
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>Detonix</h1>
          <p>
            Welcome to Detonix, where the excitement never ends. Dive into the action and experience the thrill of the game.
            Prepare for a pulse-pounding adventure with Detonix, the ultimate game for thrill-seekers and strategy enthusiasts alike. In Detonix, you’ll dive into a high-stakes environment 
            where every decision counts and every move can make the difference between victory and defeat
          </p>
          <div className="d-flex justify-content-between mt-3">
            
            <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detonix;
