import React, { useState } from "react";
import logoforesttheme from "../images/gamesimage/daipass/logoforesttheme.png";
import pasa from "../images/gamesimage/daipass/pasa.png";
import bg from "../images/gamesimage/daipass/bg.png";
import bg1 from "../images/gamesimage/nms_teenpati/bg1.png";
import logo1 from "../images/gamesimage/nms_teenpati/logo1.png";
import teen__patti from "../images/gamesimage/nms_teenpati/teen__patti.png";
import nms_quiz_bg from "../images/gamesimage/nms_quiz/nms_quiz_bg.jpg";
import nms_quiz_characters from "../images/gamesimage/nms_quiz/nms_quiz_characters.png";
import bg2 from "../images/gamesimage/casino/bg2.png";
import logo2 from "../images/gamesimage/casino/logo2.png";
import chips from "../images/gamesimage/casino/chips.png";
import nms_quiz_logo from "../images/gamesimage/nms_quiz/nms_quiz_logo.png";
import bg4 from "../images/gamesimage/ludogame/bg4.png";
import logo4 from "../images/gamesimage/ludogame/logo4.png";
import ludo from "../images/gamesimage/ludogame/ludo.png";
import bg5 from "../images/gamesimage/tambola/bg5.png";
import logo5 from "../images/gamesimage/tambola/logo5.png";
import boards1 from "../images/gamesimage/tambola/boards1.png";
import bg6 from "../images/gamesimage/fun__tt/bg6.png";
import logo6 from "../images/gamesimage/fun__tt/logo6.png";
import roulette from "../images/gamesimage/fun__tt/roulette.png";
import bg7 from "../images/gamesimage/BigWinExchange/bg7.png";
import Logo7 from "../images/gamesimage/BigWinExchange/Logo7.png";
import space_loading_fill from "../images/gamesimage/BigWinExchange/space_loading_fill.gif";
import bg8 from "../images/gamesimage/carrom/bg8.png";
import board3 from "../images/gamesimage/carrom/board3.png";
import logo8 from "../images/gamesimage/carrom/logo8.png";
import logo9 from "../images/gamesimage/poker/logo9.png";
import bg9 from "../images/gamesimage/poker/bg9.png";
import poker from "../images/gamesimage/poker/poker.png";
import bg10 from "../images/gamesimage/roulette/bg10.png";
import logo10 from "../images/gamesimage/roulette/logo10.png";
import roulette1 from "../images/gamesimage/roulette/roulette1.png";
import logo11 from "../images/gamesimage/rummy/logo11.png";
import nms_rummy from "../images/gamesimage/rummy/nms_rummy.png";
import cricket from "../images/gamesimage/jollycricket/cricket.jpeg";
import knight from "../images/gamesimage/Knight/knight.jpg";
import ChessknightImage from "../images/gamesimage/chessknight/chessknight.jpeg"
import BubbleShooterImage from "../images/gamesimage/bubble_shooter/bubbleshotter.png";
import venturexImage from '../images/gamesimage/Venturex/venturex.jpg';
import DetonixImage from "../images/gamesimage/Detonix/detonix.jpg"
import CardsImage from "../images/gamesimage/Cards/cards.jpg"
import InfernoBrawlImage from "../images/gamesimage/InfernoBrawl/infernobrawl.jpg"
import JollyHockeyImage from '../images/gamesimage/JollyHockey/jollyhockey.jpg';
import JollyFootballImage from '../images/gamesimage/JollyFootball/jollyfootball.jpg';
import NinjaKillImage from '../images/gamesimage/NinjaKill/ninjakill.jpg';
import UPBallsImage from '../images/gamesimage/UPBalls/upballs.jpg';

import "./Games.css";
import { useNavigate } from "react-router-dom";


const Games = () => {

         // Ninja Kill
     const navigateUPBalls = useNavigate();
     function handleUPBalls(event) {
     event.preventDefault();
     navigateUPBalls("/UPBalls");
    }
        // Ninja Kill
     const navigateNinjaKill = useNavigate();
     function handleNinjaKill(event) {
     event.preventDefault();
     navigateNinjaKill("/NinjaKill");
    }

        // Jolly Football
     const navigateJollyFootball = useNavigate();
     function handleJollyFootball(event) {
     event.preventDefault();
     navigateJollyFootball("/JollyHockey");
    }

     // Jolly Hockey
     const navigateJollyHockey = useNavigate();
     function handleJollyHockey(event) {
     event.preventDefault();
     navigateJollyHockey("/JollyHockey");
    }

    // Inferno Brawl
    const navigateInfernoBrawl = useNavigate();
    function handleInfernoBrawl(event) {
    event.preventDefault();
    navigateInfernoBrawl("/InfernoBrawl");
   }

   // Cards
   const navigateCards = useNavigate();
   function handleCards(event) {
   event.preventDefault();
   navigateCards("/Cards");
  }

  // Detonix
  const navigateDetonix = useNavigate();
  function handleDetonix(event) {
  event.preventDefault();
  navigateDetonix("/Detonix");
}
  //Venturex
    const navigateventurex = useNavigate();
      function handleventurex(event) {
      event.preventDefault();
      navigateventurex("/Venturex");
  }
    //chess knight
  const navigatechessknight = useNavigate();
  function handlechessknight(event) {
    event.preventDefault();
    navigatechessknight("/Chessknight");
  }
   // Knight
   const navigateKnight = useNavigate();
   function handleKnight(event) {
     event.preventDefault();
     navigateKnight("/Knight");
   }

  // Casino Game
  const navigateCasinoGame = useNavigate();
  function handleCasinoGame(event) {
    event.preventDefault();
    navigateCasinoGame("/casino");
  }

  // Rummy Game
  const navigateRummyGame = useNavigate();
  function handleRummyGame(event) {
    event.preventDefault();
    navigateRummyGame("/rummy");
  }

  //Poker Game
  const navigatePokerGame = useNavigate();
  function handlePokerGame(event) {
    event.preventDefault();
    navigatePokerGame("/poker");
  }

  //Jolly Cricket
  const navigateCricket = useNavigate();
  function handleCricketGame() {
    navigateCricket("/JollyCricket");
  }

  //Bubble shooter
  const navigateBubbleShooter = useNavigate();
  function handleBubbleShooter() {
    navigateBubbleShooter("/BubbleShooter");
  }

  //Carrom Game
  const navigateCarromGame = useNavigate();
  function handleCarromGame() {
    navigateCarromGame("/carrom");
  }

  //Daipass Game
  const navigateDaipass = useNavigate();
  function handleDaipass() {
    navigateDaipass("/daipass");
  }

  //Tambola
  const navigateTambola = useNavigate();
  function handleTambola() {
    navigateTambola("/tambola");
  }

  //Quiz Game
  const navigateQuizGame = useNavigate();
  function handleQuizGame() {
    navigateQuizGame("/quiz");
  }

  //Teenpatti
  const navigateTeenpatti = useNavigate();
  function handleTeenpatti() {
    navigateTeenpatti("/teenpatti");
  }

  //Fun target timer
  const navigateFunTarget = useNavigate();
  function handleFunTarget() {
    navigateFunTarget("/funtarget");
  }

  //Big Win Casino Game
  const navigateBigWinCasino = useNavigate();
  function handleBigWinExchange() {
    navigateBigWinCasino("/bigwincasino");
  }

  //Ludo game
  const navigateLudoGame = useNavigate();
  function handleLudoGame() {
    navigateLudoGame("/ludogame");
  }

  //Roulette Game
  const navigateRouletteGame = useNavigate();
  function handleRouletteGame(){
    navigateRouletteGame('/roulette');
  }


  //handle See all games
  const [showAllGames, setShowAllGames] = useState(false);

  const handleSeeAllGames = () => {
    setShowAllGames(!showAllGames);
  };

  return (
    <section id="games" className="section-games text-center ">
      <div className="row title-row">
        <div className="purple-hr"></div>
        <div className="col-12 title-col">
          <h1 className="section-title">Our Games</h1>
        </div>
      </div>

      <div className="row container-games">
        {/* Casino Game */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleCasinoGame}>
            <div
              className="game-card"
              style={{ background: `url(${bg2})`, backgroundSize: "cover" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo2}
                alt=""
                className="img-fluid card-img-top p-3"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={chips}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Rummy Game */}

        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleRummyGame}>
            <div
              className="game-card"
              style={{ background: "#1b0044", backgroundSize: "contain" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo11}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={nms_rummy}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/*  Poker Game  */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handlePokerGame}>
            <div
              className="game-card"
              style={{ background: `url(${bg9})`, backgroundSize: "cover" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo9}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={poker}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Roulette Game */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleRouletteGame}>
            <div
              className="game-card"
              style={{ background: `url(${bg10})`, backgroundSize: "cover" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo10}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={roulette1}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* TeenPatti */}

        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleTeenpatti}>
            <div
              className="game-card teenpatti"
              style={{ background: `url(${bg1})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo1}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={teen__patti}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Dai Pass */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleDaipass}>
            <div
              className="game-card"
              style={{ background: `url(${bg})`, backgroundSize: "cover" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logoforesttheme}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={pasa}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Quiz game */}

        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleQuizGame}>
            <div
              className="game-card"
              style={{ background: `url(${nms_quiz_bg})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={nms_quiz_characters}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={nms_quiz_logo}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Ludo Game */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleLudoGame}>
            <div
              className="game-card"
              style={{ background: `url(${bg4})`, backgroundSize: "cover" }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo4}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={ludo}
                alt=""
                className="img-fluid card-img-bottom p-1"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        
        {showAllGames && (
          <>
        {/* Tambola Game */}
        
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleTambola}>
            <div
              className="game-card"
              style={{ background: `url(${bg5})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo5}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={boards1}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/*  Fun Target Timer Game  */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleFunTarget}>
            <div
              className="game-card"
              style={{ background: `url(${bg6})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo6}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={roulette}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/*  BiG WiN Casino Game  */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleBigWinExchange}>
            <div
              className="game-card"
              style={{ background: `url(${bg7})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={Logo7}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={space_loading_fill}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Carrom Game */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleCarromGame}>
            <div
              className="game-card"
              style={{ background: `url(${bg8})` }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={logo8}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />

              <img
                src={board3}
                alt=""
                className="img-fluid card-img-top"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
            </div>
          </button>
        </div>

        {/* Jolly Cricket */}

        
    <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
      <button onClick={handleCricketGame}>
        <div
          className="game-card"
          style={{ background: `url(${cricket})`, backgroundSize: 'cover', width: '300px' }}
        >
          <img
            src={cricket}
            alt="Cricket"
            className="img-fluid card-img-bottom"
          />
        </div>
      </button>
    </div>

            {/* Knight */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
        <button onClick={handleKnight}>
            <div
              className="game-card"
              style={{ background: `url(${knight})`, backgroundSize: 'cover', width: '300px' }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={knight}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
              </div>
              </button>
              </div>

               {/*Chess Knight */}
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
        <button onClick={handlechessknight}>
            <div
              className="game-card"
              style={{ background: `url(${ChessknightImage})`, backgroundSize: 'cover', width: '300px' }}
              data-tilt-max="10"
              data-tilt
              data-tilt-speed="400"
              data-tilt-perspective="500"
            >
              <img
                src={ChessknightImage}
                alt=""
                className="img-fluid card-img-bottom"
                data-tilt
                data-tilt-max="25"
                data-tilt-speed="400"
                data-tilt-perspective="500"
              />
              </div>
              </button>
              </div>

          {/*Venturex*/}    
        <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
        <button onClick={handleventurex}>
      <div
        className="game-card"
        style={{ background: `url(${venturexImage})`, backgroundSize: 'cover', width: '300px'  }}  
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={venturexImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

          {/*Detonix*/}    
          <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleDetonix}>
      <div
        className="game-card"
        style={{ background: `url(${DetonixImage})`, backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={DetonixImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

         {/*Cards*/}    
         <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
         <button onClick={handleCards}>
      <div
        className="game-card"
        style={{ background: `url(${CardsImage})`, backgroundSize: 'cover', width: '300px'  }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={CardsImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
         />
          </div>
          </button>
           </div>

           {/*Inferno Brawl*/}    
         <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleInfernoBrawl}>
      <div
        className="game-card"
        style={{ background: `url(${InfernoBrawlImage})`, backgroundSize: 'cover', width: '300px'  }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={InfernoBrawlImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

        
           {/*Jolly Hockey*/}    
           <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
            <button onClick={handleJollyHockey}>
      <div
        className="game-card"
        style={{ background: `url(${JollyHockeyImage})`, backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={JollyHockeyImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

            {/*Jolly Football*/}    
            <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
              <button onClick={handleJollyFootball}>
      <div
        className="game-card"
        style={{ background: `url(${JollyFootballImage})` , backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
        
      >
        <img
          src={JollyFootballImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

         {/*Ninja Kill*/}    
         <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleNinjaKill}>
      <div
        className="game-card"
        style={{ background: `url(${NinjaKillImage})`, backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
        
      >
        <img
          src={NinjaKillImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

            {/*UP ball*/}    
         <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleUPBalls}>
      <div
        className="game-card"
        style={{ background: `url(${UPBallsImage})`, backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={UPBallsImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

          


          {/* Bubble Shooter*/}
          <div className="col-sm-6 col-md-4 col-lg-3 col-game-card">
          <button onClick={handleBubbleShooter}>
      <div
        className="game-card"
        style={{ background: `url(${BubbleShooterImage})`, backgroundSize: 'cover', width: '300px' }}  // Correct usage
        data-tilt-max="10"
        data-tilt
        data-tilt-speed="400"
        data-tilt-perspective="500"
       
      >
        <img
          src={BubbleShooterImage}  // Correct usage
          alt="Venturex"
          className="img-fluid card-img-bottom"
          data-tilt
          data-tilt-max="25"
          data-tilt-speed="400"
          data-tilt-perspective="500"
        />
        </div>
        </button>
        </div>

        </>
        
        )}

      </div>
   
            

      <div className="row row-btn-show-all">
        <div className="col-12 col-btn-show-all text-center my-5">
        <button onClick={handleSeeAllGames} className="btn-show-all">
            {showAllGames ? 'Show Less' : 'See All Games'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Games;
