import React from "react";

import banner from "../images/banner.png";
import cloud1 from "../images/cloud1.png";
import cloud2 from "../images/cloud2.png";
import cloud3 from "../images/cloud3.png";
import bg from '../images/bg.png';
import "./Banner.css";

const Banner = () => {
  return (
    <div className="landing" id="home">
      <div
        id="caruselSlides"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="slide-1 banner-img">
              <img src={bg} className="w-100" alt="" />
              <img src={cloud1} className="cloud1 w-100" alt="" />
              <img src={cloud2} className="cloud2 w-100" alt="" />
              <img src={cloud3} className="cloud3 w-100" alt="" />
              <img src={cloud1} className="cloud4 w-100" alt="" />
              <img src={cloud2} className="cloud5 w-100" alt="" />
              <img src={cloud3} className="cloud6 w-100" alt="" />
              <img src={banner} className="w-100 heroes " alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
