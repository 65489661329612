import React from "react";
import bg8 from '../images/gamesimage/carrom/bg8.png';
import board3 from '../images/gamesimage/carrom/board3.png';
import logo8 from '../images/gamesimage/carrom/logo8.png';
import { useNavigate } from "react-router-dom";

const Carrom = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/bigwincasino'); // Replace with the path for the previous game
  }

  function handleNext() {
    navigate('/jollyCricket'); // Replace with the path for the next game
  }

  return (
    <div className="container my-5 w-75">
      <button
              onClick={handlePrevious}
              className="btn btn-secondary me-2"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous Game</span>
            </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={board3}
                    className="d-block w-100 img-fluid"
                    style={{ width: '350px', height: '350px' }}
                    alt="Logo"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={board3}
                    className="d-block w-100 img-fluid"
                    alt="Board"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Carrom</h1>
          <p>
            Welcome to Carrom, where the thrill of the game meets the
            excitement of winning. Our carrom offers a wide range of games and
            entertainment for all types of players. Join us for an unforgettable
            experience.
          </p>
          {/* Navigation buttons outside the carousel */}
          <div className="d-flex justify-content-between mt-3">
            
            <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrom;
