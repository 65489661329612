import React from "react";
import ChessknightImg from "../images/gamesimage/chessknight/chessknight.jpeg";
import { useNavigate } from "react-router-dom";

const Chessknight = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/Knight'); // Replace with the path for the previous game
  }

  function handleNext() {
    navigate('/Venturex'); // Replace with the path for the next game
  }

  function navigateToDestination() {
    navigate('/destination'); // Ensure this route is correctly set up in your router
  }

  return (
    <div className="container my-5 w-75">
      <button
              onClick={handlePrevious}
              className="btn btn-secondary me-2"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous Game</span>
            </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsChessknight"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={ChessknightImg}
                    className="d-block w-100"
                    alt="Chessknight"
                    onClick={navigateToDestination}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Chess Knight</h1>
          <p>
            Welcome to Chessknight, where strategic gameplay meets exciting challenges.
            Step into the world of strategy and intellect with Chess Knight, 
            an enthralling chess-based game that combines classic chess mechanics 
            with exciting new twists. Whether you’re a seasoned chess player or a newcomer eager to learn, 
            Chess Knight offers a challenging and immersive experience that will sharpen 
            your strategic thinking and problem-solving skills.
          </p>
          {/* Navigation buttons outside the carousel */}
          <div className="d-flex justify-content-between mt-3">
            
          <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chessknight;
