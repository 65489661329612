import React from "react";
import CardsImage from "../images/gamesimage/Cards/cards.jpg"; 
import { useNavigate } from "react-router-dom";

const Cards = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/Detonix'); 
  }

  function handleNext() {
    navigate('/InfernoBrawl'); 
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={handlePrevious} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsCards"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={CardsImage}
                    className="d-block w-100 img-fluid"
                    alt="Card image"
                    style={{ width: '350px', height: '350px' }} // Adjust size as needed
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Cards</h1>
          <p>
          Dive into the excitement of Cards, a captivating game that combines strategy,
          skill, and a touch of luck! This classic card game brings a fresh twist to traditional card play,
          offering a variety of game modes and challenges to keep you on your toes.
          </p>
          {/* Navigation buttons outside the carousel */}
          <div className="d-flex justify-content-between mt-3">
           
              
            <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
