import React from "react";
import { useNavigate } from "react-router-dom";
import knight from '../images/gamesimage/Knight/knight.jpg';

const Knight = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/jollyCricket'); 
  }

  function handleNext() {
    navigate('/ChessKnight');
  }

  return (
    <div className="container my-5 w-75">
      <button
              onClick={handlePrevious}
              className="btn btn-secondary me-2"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous Game</span>
            </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsKnight"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={knight}
                    className="d-block w-100"
                    alt="Knight"
                    style={{ width: '350px', height: '350px' }} // Adjust size as needed
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Knight</h1>
          <p>
          Embark on a daring adventure in Knight, a thrilling game where strategy and skill come 
          together to test your mettle. Set in a medieval world filled with challenges and excitement,
           Knight offers an engaging experience for players who love tactical gameplay and epic quests.
          </p>
          {/* Navigation buttons outside the carousel */}
          <div className="d-flex justify-content-between mt-3">
           
          <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Knight;
