import React from "react";
import jollyFootballImage from '../images/gamesimage/JollyFootball/jollyfootball.jpg';  // Ensure this path is correct
import { useNavigate } from "react-router-dom";

const JollyFootball = () => {
  const navigatePrevious = useNavigate();
  function navigateToPreviousGame() {
    navigatePrevious("/JollyHockey"); // Replace with the actual route for the previous game
  }

  const navigateNext = useNavigate();
  function navigateToNextGame() {
    navigateNext("/NinjaKill"); // Replace with the actual route for the next game
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToPreviousGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={jollyFootballImage} className="d-block w-100" alt="Football" />
                </div>
                {/* Additional carousel items can go here */}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Jolly Football</h1>
          <p>
            
            Welcome to Jolly Football, where you can enjoy the excitement and thrill of the game. Explore our collection and have a great time!
            Get ready to kick off your passion for football with Jolly Football! This thrilling game brings the 
            excitement of the beautiful game right to your fingertips, 
            combining fast-paced action with strategic gameplay for an unforgettable football experience.
          </p>
          <button onClick={navigateToNextGame} className="btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default JollyFootball;
