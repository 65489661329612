import React from "react";
import InfernoBrawlImage from "../images/gamesimage/InfernoBrawl/infernobrawl.jpg"; // Correct image import
import { useNavigate } from "react-router-dom";

const InfernoBrawl = () => {
  const navigate = useNavigate();

  function handlePreviousGame() {
    navigate('/Cards'); 
  }

  function handleNextGame() {
    navigate('/JollyHockey'); 
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={handlePreviousGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsInferno"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={InfernoBrawlImage}
                    className="d-block w-100 img-fluid"
                    style={{ width: '350px', height: '350px' }} // Adjust size as needed
                    alt="Inferno Brawl"
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControlsInferno"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControlsInferno"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>Inferno Brawl</h1>
          <p>
            Welcome to Inferno Brawl, where the excitement never ends. Dive into the action and experience the thrill of the game.
            Step into the fiery arena of Inferno Brawl, where the heat of battle meets
            intense competition in an epic showdown. In Inferno Brawl, you’ll face off against 
            fierce opponents in a dynamic and fast-paced environment that will test your skills and strategic prowess.
          </p>
          <button onClick={handleNextGame} className="btn btn-secondary">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfernoBrawl;
