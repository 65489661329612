import React from "react";
import './Footer.css';

const Footer = () =>{
    return(
        <footer>
			<div className="row justify-content-center">
				<div id="map" className="md-4"></div>

				<div className="col-md-6 text-center">
					<strong>Head Office</strong><br /><br />
					<p>
						<b>NMS Games Private Limited ,</b> Yashwant Nagar,<br/>Talegaon, <br/>Pune - 410507
					</p>
				</div>

				

				<div className="col-md-6 text-center">
					<strong>Contact</strong>
					<p>
						<i className="fas fa-phone" style= {{fontSize: '0.9rem'}}></i>&nbsp;+91 8355902518 <br/>contact@nmsgames.in <br /><br />
						<a href="https://www.facebook.com/NMS-Games-101110388415034" target="_blank"><i
								className="fab fa-facebook-square fa-3x"></i></a>

						<a href="https://www.instagram.com/nmsgamesstudio/" target="_blank" 
							style = {{margin: '10px'}}><i
								className="fab fa-instagram-square fa-3x"></i></a>
					
						<a href="https://www.linkedin.com/company/nms-games" target="_blank" 
							style = {{margin: '1px'}}><i
								className="fab fa-linkedin fa-3x"></i></a>

						
					</p>
				</div>
			</div>

				<div class="row" style = {{paddingBottom: '35px'}}>
					<hr class="socket" />
					<div class="col-12 text-center">&copy; NMS Games Pvt. Ltd. 2024 </div>
				</div>
            </footer>
    );

}

export default Footer;