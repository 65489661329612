import React from "react";
import './Features.css';

const Features = () =>{
    return(
        <section id="features" className="section-features text-center">
			<div className="row title-row pt-6">
				<div className="purple-hr"></div>
				<div className="col-12 title-col">
					<h1 className="section-title text-white">Why you should choose NMS Games for mobile game development?</h1>
				</div>
			</div>
			<div className="feature-content-container golden-glass-container">
				<div className="row">
					<div className="col-12">
						<p className = "text-white p2">
							One-Stop-Shop for all mobile game development services needs to get your dream into reality.
							NMS GAMES as a game development service provider for iOS and Android with finger count
							people and now we a big team.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<p className = "text-white p2">
							Our standards are on par with the best mobile gaming companies in the industry. Project
							development process with agile methodology. We assure our developed game or app works
							seamlessly well across all types of mobile phones and tablets.
						</p>
					</div>
				</div>
			</div>

			<div className="row title-row">
				<div className="col-12 title-col">
					<h1 className="section-title text-white">End to end game design, development, deployment, suppport and
						maintainannce.
					</h1>
				</div>
			</div>
			<div className="feature-content-container purple-glass-container">
				<div className="row">
					<div className="col-12">
						<p className = "text-white p2"> 
							One-Stop-Shop for all mobile game development services needs to get your dream into reality.
							NMS GAMES as a game development service provider for iOS and Android with finger count
							people and now we a big team.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<p className = "text-white p2">
							Our standards are on par with the best mobile gaming companies in the industry. Project
							development process with agile methodology. We assure our developed game or app works
							seamlessly well across all types of mobile phones and tablets.
						</p>
					</div>
				</div>
			</div>

		</section>
    );
}

export default Features;