import React from "react";
import dice2 from "../images/gamesimage/ludogame/dice2.png";
import ludo from "../images/gamesimage/ludogame/ludo.png";
import { useNavigate } from "react-router-dom";

const LudoFantoosh = () => {
  const navigateTambolaGame = useNavigate();
  function navigateToTambola() {
    navigateTambolaGame("/tambola");
  }

  const navigateQuizGame = useNavigate();
  function navigateToQuizGame(){
    navigateQuizGame('/quiz');
  } 

  return (
    <div className="container my-5 w-75" style={{ borderRadius: "15px" }}>

      <button onClick={navigateToQuizGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div
        className="row align-items-center casino-card"
        style={{ borderRadius: "15px" }}
      >
        <div className="col-md-6 text-center">
          <div className="card carousel-card" style={{ borderRadius: "15px" }}>
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-item">
                <img
                  src={dice2}
                  className="d-block img-fluid glowing-image "
                  alt="Second slide"
                  style={{ backgroundSize: "cover" }}
                />
              </div>
              <div className="carousel-inner" style={{ borderRadius: "18px" }}>
                

                <div className="carousel-item active">
                  <img
                    src={dice2}
                    className="d-block img-fluid glowing-image "
                    alt="Second slide"
                    style={{ backgroundSize: "cover" }}
                  />
                </div>

                <div className="carousel-item">
                  <img
                    src={ludo}
                    className="d-block img-fluid glowing-image "
                    alt="Fourth slide"
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>NMS Casino</h1>
          <p>
            Welcome to NMS Casino, where the thrill of the game meets the
            excitement of winning. Our casino offers a wide range of games and
            entertainment for all types of players. Join us for an unforgettable
            experience.
          </p>
          <button onClick={navigateToTambola} className="btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden"></span>
            
          </button>
        </div>
      </div>
    </div>
  );
};

export default LudoFantoosh;
