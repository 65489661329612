import React from "react";
import BubbleShooterImage from '../images/gamesimage/bubble_shooter/bubbleshotter.png'; // Ensure the path is correct
import { useNavigate } from "react-router-dom";

const BubbleShooter = () => {
  const navigatePrevious = useNavigate();
  function navigateToPreviousGame() {
    navigatePrevious("/UPBalls");
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToPreviousGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={BubbleShooterImage}
                    className="d-block w-100"
                    alt="Bubble Shooter"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Bubble Shooter</h1>
          <p>
          Step into the colorful and engaging world of Bubble Shooter,
          where quick thinking and sharp reflexes lead to victory!
          In this classic and addictive puzzle game, your goal is to clear the screen
          of bubbles by matching three or more of the same color. 
          Aim carefully and shoot bubbles to create chains and clusters, causing them to pop and disappear.
          </p>
        
        </div>
      </div>
    </div>
  );
};

export default BubbleShooter;
