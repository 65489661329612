import React from "react";
import Logo7 from "../images/gamesimage/BigWinExchange/Logo7.png";
import space_loading_fill from "../images/gamesimage/BigWinExchange/space_loading_fill.gif";
import { useNavigate } from "react-router-dom";
import "./GamesDetails.css";

const BigExchangeGame = () => {
  const navigateCarromGame = useNavigate();
  function navigateToCarrom() {
    navigateCarromGame("/carrom");
  }

  const navigateFunTarget = useNavigate();
  function navigateToFuntarget(){
    navigateFunTarget('/funtarget');
  }

  return (
    <div className="container my-5 w-75">

      <button onClick={navigateToFuntarget} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item">
                  <img
                    src={Logo7}
                    className="d-block w-100 img-fluid"
                    style={{ width: "350px", height: "350px" }}
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item active">
                  <img
                    src={space_loading_fill}
                    className="d-block w-75 ml-5 img-fluid"
                    alt="Fourth slide"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next custom-next-btn"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>Big Win Exchange Casino</h1>
          <p>
            Welcome to Big Win Exchange Casino, where the thrill of the game
            meets the excitement of winning. Our Big Win Exchange Casino offers
            a wide range of games and entertainment for all types of players.
            Join us for an unforgettable experience.
          </p>
          <button onClick={navigateToCarrom} className="btn btn-secondary" style = {{
            display: 'flex'}}>
            <span
              className="carousel-control-next-icon"
              
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigExchangeGame;
