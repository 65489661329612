import React from "react";
import './Expertise.css';


const Expertise = () => {
    return (
        <div className="container">
            <section id="expertise" className="section-expertise text-center">
                <div className="row title-row">
                    <div className="purple-hr"></div>
                    <div className="col-12 title-col">
                        <h1 className="section-title text-white"> Our Expertise</h1>
                    </div>
                </div>

                <div className="expertise-list-outer-container purple-glass-container-2">
                    <div className="row text-start">
                        <div className="col-12">
                            <h2 className = "text-white">
                                The area of expertise in Mobile Game Development are:
                            </h2>
                            <ul className="expertise-list text-white p2">
                                <li>
                                    Developing Cross platform supporting game apps
                                </li>
                                <li>
                                    Unity3D Development
                                </li>
                                <li>
                                    HTML5 Game development
                                </li>
                                <li>
                                    Mobile AR game Appsstudio
                                </li>
                                <li>
                                    Mobile Simulation Game Apps
                                </li>
                                <li>
                                    Mobile VR game apps development
                                </li>
                                <li>
                                    Mobile UI/UX Design
                                </li>
                                <li>
                                    Mobile Game Art Asset Creation
                                </li>
                                <li>
                                    Hybrid Game App Development
                                </li>
                                <li>
                                    We develop game for PC, iPhone, iPad, Android Phone and Tablet devices.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section id="services" className="section-services text-center">
                <div className="row title-row">
                    <div className="golden-hr"></div>
                    <div className="col-12 title-col">
                        <h1 className="section-title text-white"
                            style={{ backgroundColor: 'unset', boxShadow: 'unset', textShadow: '3px 3px 3px #000' }} >Services We Offer
                        </h1>
                    </div>
                </div>

                <div className="services-team-container black-glass-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p style={{ margin: '2rem 0' }} className = "text-white p2">
                                We have team of passionate designers and developer specialized in developing games for iOS
                                and
                                Android mobile platforms using popular game engines. We have extensive experience in using
                                tools
                                and technologies to build games that support cross-platform. Art, Design & Animation We have
                                pool of creative and talented art and design team, who create appealing Graphics, UI,
                                game-assets for 2D, Isometric and 3D mobile games. Team is skilled at creating assets and
                                animations that are mobile friendly and highly optimized to perform well. Click here to read
                                more about our art and design services. Here we have expounded upon the way how our artists
                                and
                                animators deal with various art aspects.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="services-container golden-glass-container mt-5" 
                    >
                    <div className="row">
                        <div className="col-12 pt-2" >
                            <h2 className = "text-white">
                                UI/UX
                            </h2>
                        </div>
                        <div className="col-12">
                            <p className = "text-white p2">
                                The UI/UX design process for mobile games is not only required experience in design like how
                                to
                                work with colors, fonts, button sizes and also requires a logical process, one that’s
                                perfectly
                                approachable to a developer. As a top mobile game development company, we follow best
                                practices
                                for UI design. Quite like our game developers our UI/UX developers profusely interact with
                                various interfaces to better develop apt UI/UX design.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="services-container purple-glass-container" 
                    style = {{marginTop: '50px'}}>
                    <div className="row">
                        <div className="col-12 ">
                            <h2 className = "text-white">
                                Coding & Development
                            </h2>
                        </div>
                        <div className="col-12">
                            <p className = "text-white p2">
                                We are experienced in all popular mobile game development engines like Unity, Unreal and
                                HTML5,
                                Angular JS, Three.JS, JavaScript framework etc… to build native and cross-platform games. We
                                select the right set of tools and technologies based on the games technical requirement.
                                Looking
                                to hire mobile game development resource with experience for the project you are at right
                                place.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="services-container golden-glass-container"
                    style = {{marginTop: '50px'}}>
                    <div className="row">
                        <div className="col-12">
                            <h2 className = "text-white">
                                Testing
                            </h2>
                        </div>
                        <div className="col-12">
                            <p className = "text-white p2">
                                Our testing team’s enhanced gaming experience will help to provide Quality Assurance and
                                compliance testing for iOS and Android games. We test game’s consistent performance,
                                gameplay,
                                device compatibility, user experience, user interface, screen orientations, resolutions,
                                load
                                testing, social integration etc. The Centre of Excellence (CoE) aimed to help gaming
                                companies
                                to leverage the benefits of speed, efficiency aligned with its testing and development goals
                            </p>
                        </div>
                    </div>
                </div>
                <br /><br />
            </section>
        </div>
    );
}

export default Expertise;