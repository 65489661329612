import React from "react";
import clubace from "../images/tennpatti/clubace.png";
import diamondace from "../images/tennpatti/diamondace.png";
import heartace from "../images/tennpatti/heartace.png";
import teenpatti from "../images/tennpatti/teenpatti.png";
import spadeace from "../images/tennpatti/spadeace.png";
import { useNavigate } from "react-router-dom";

const Teenpatti = () => {
  const navigateDaipass = useNavigate();
  function navigateToDaiPass() {
    navigateDaipass("/daipass");
  }

  const navigateRoulette = useNavigate();
  function navigateToRoulette(){
    navigateRoulette('/roulette');
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToRoulette} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={clubace}
                    className="d-block w-100 img-fluid"
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item ">
                  <img
                    src={diamondace}
                    className="d-block w-100 img-fluid"
                    alt="Fourth slide"
                  />
                </div>

                <div className="carousel-item">
                  <img
                    src={heartace}
                    className="d-block w-100 img-fluid"
                    alt="Fourth slide"
                  />
                </div>

                <div className="carousel-item ">
                  <img
                    src={spadeace}
                    className="d-block w-100 img-fluid"
                    alt="Fourth slide"
                  />
                </div>

                <div className="carousel-item ">
                  <img
                    src={teenpatti}
                    className="d-block w-100 img-fluid"
                    alt="Fourth slide"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>Teenpatti</h1>
          <p>
            Welcome to Teenpatti, where the thrill of the game meets the
            excitement of winning. Our Teenpatti offers a wide range of games
            and entertainment for all types of players. Join us for an
            unforgettable experience.
          </p>
          <button onClick={navigateToDaiPass} className="btn btn-secondary ">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Teenpatti;
