import React from "react";
// import golden from '../images/golden.png';
import './About.css';
import namo from '../images/namo.png';


const About = () => {
    return (
    <>
		<section id="about" className="section-about text-center">
			<div className="golden-hr"></div>
			<div className="row about-row black-glass-container">
				<div className="col-12 about-col text-center">
					<p className="about-para text-white p2">
						Mobile games have more potential and huge market in both revenue and user base it accounts for
						more than 50% of the global gaming industry revenue with 25% year on year growth rate. NMS Games
						is one of the top mobile game companies in India, offering mobile game development services to
						clients.
					</p>
				</div>

				<div className="col-12 about-col text-center">
					<p className="about-para text-white p2">
						We have expertise in developing games across all genre from engaging casual, social, card,
						casino, action, arcade, trivia, strategy, puzzle, educational, table and board games for iOS and
						Android for both phone and tablet. We use Unity3D, Phasor, Construct2/3, PlayCanvas, Blender,
						Illustrator, Maya, Max, Photoshop and many other industry-leading tools to create the best games
						and beast experiences and games, apps for Mobile AR and VR platforms.
					</p>
				</div>
			</div>
			<div className="row about-row black-glass-container">
				<div className="col-12 text-center">
					<img src={namo} alt="Indian-toys-global-playground" className="img-fluid" />
				</div>
				<div className="col-12 text-center">
                <div class="video-container">
						<iframe width="560" height="315" src="https://www.youtube.com/embed/9U7f4iixKlQ" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen>
						</iframe>
					</div>
				</div>

				
			</div>

		</section>


		

		</>
		
    );
}

export default About;