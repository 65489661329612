import React from "react";
import NinjaKillImage from '../images/gamesimage/NinjaKill/ninjakill.jpg'; // Verify this path
import { useNavigate } from "react-router-dom";

const NinjaKill = () => {
  const navigatePrevious = useNavigate();
  function navigateToPreviousGame() {
    navigatePrevious("/JollyFootball"); // Replace with the actual route for the previous game
  }

  const navigateNext = useNavigate();
  function navigateToNextGame() {
    navigateNext("/UPBalls"); // Replace with the actual route for the next game
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToPreviousGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={NinjaKillImage}
                    className="d-block w-100"
                    alt="Ninja Kill"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Ninja Kill</h1>
          <p>
          Embark on a daring adventure in Knight, a thrilling game where strategy and skill 
          come together to test your mettle. Set in a medieval world filled with challenges and excitement,
           Knight offers an engaging experience for players who love tactical gameplay and epic quests.
          </p>
          <button onClick={navigateToNextGame} className="btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NinjaKill;
