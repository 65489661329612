import React from "react";
import nms_rummy from "../images/gamesimage/rummy/nms_rummy.png";
import rummy1 from "../images/gamesimage/rummy/rummy1.png";
import "./GamesDetails.css";
import { useNavigate } from "react-router-dom";

const NMSRummy = () => {
  const navigatePokerGame = useNavigate();
  function navigateToPoker() {
    navigatePokerGame("/poker");
  }

  const navigateCasinoGame = useNavigate();
  function navigateToCasino(){
    navigateCasinoGame('/casino');
  }

  return (
    <div className="container my-5 w-75" style={{ borderRadius: "15px" }}>
      <button onClick={navigateToCasino} className = "btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <div
        className="row align-items-center casino-card"
        style={{ borderRadius: "15px" }}
      >
        <div className="col-md-6 text-center" style={{ borderRadius: "15px" }}>
          <div className="card carousel-card" style={{ borderRadius: "15px" }}>
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
              style={{ borderRadius: "15px" }}
            >
              <div className="carousel-inner" style={{ borderRadius: "15px" }}>
                <div className="carousel-item active">
                  <img
                    src={nms_rummy}
                    className="d-block w-100 glowing-image"
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={rummy1}
                    className="d-block w-100"
                    alt="Third slide"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-content">
          <h1>NMS Rummy</h1>
          <p>
            Welcome to NMS Rummy, where the thrill of the game meets the
            excitement of winning. Our rummy offers a wide range of games and
            entertainment for all types of players. Join us for an unforgettable
            experience.
          </p>
          <button onClick={navigateToPoker} className = "btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NMSRummy;
