import React, { useState } from "react";
import "./Enquiry.css";
import axios from 'axios';
const Enquiry = () => {

	const[formData, setFormData] = useState({
		name: '',
		email: '',
		phone: '',
		message: ''
	});

	const handleChange = (e) =>{
		setFormData({...formData, [e.target.name]: e.target.value})
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		axios.post('https://nmsgames.in/api/send', formData)
		  .then(response => {
			alert('Message sent successfully!');
		  })
		  .catch(error => {
			console.error('There was an error sending the message!', error);
		  });
	  };


  return (
    <section id="contact" className="section-contact">
			<div className="row title-row text-center" >
				<div className="golden-hr"></div>
				<div className="col-12 title-col">
					<h1 className="section-title head1" style={{backgroundColor: 'unset'}}>Enquire Now</h1>
				</div>
			</div>

			<div className=" row contact-row" style = {{marginLeft: '20px', marginTop: '20px'}}>
				<div className="col-md-6 contact-info-col" 
					>
					<div className="address-block ps-2 " style = {{marginTop: '20px'}}>
						<h4 className="text-warning"><i className="fas fa-map-marker-alt"></i> Office Address</h4>
						<p>
						NMS Games Private Limited, Yashwant Nagar, <br/>
						Talegaon, Pune - 410507

						</p>
					</div>
					<div className="mail-block ps-2">
						<h4 className="text-warning"><i className="far fa-envelope"></i> General Enquiries</h4>
						<p>
							<a href="mailto:contact@nmsgames.in" className = "text-white">contact@nmsgames.in</a>
						</p>
					</div>
					<div className="contact-no-block ps-2">
						<h4 className="text-warning"><i className="fas fa-phone"></i> Call Us</h4>
						<p>
							<a href="tel:+918355902518" className="text-white">+91-8355902518</a>
						</p>
					</div>
					<div className="timing-block ps-2">
						<h4 className="text-warning"><i className="fas fa-clock"></i> Our Timing</h4>
						<p>Mon - Fri : 10:00 AM - 07:00 PM</p>
					</div>
				</div>

				<div className="col-md-6 contact-form-col">

					<form onSubmit={handleSubmit} method="POST">

						<div className="form-group">
							<label for="enquirer" className="sm-hidden">Please enter your Name</label>
							<input type="text" className="form-control" id="name" name="name"
								onChange={handleChange}
								placeholder="Your Name" value = {formData.name} required />
						</div>

						<div className="form-group">
							<label for="email" className="sm-hidden">Please enter your E-Mail</label>
							<input type="email" className="form-control" id="email" name="email"
								placeholder="E-mail address" value = {formData.email}  required 
								onChange={handleChange} />
						</div>

						<div className="form-group">
							<label for="phone" className="sm-hidden">Please enter your Phone Number</label>
							<input type="tel" className="form-control" id="phone"
								value = {formData.phone} name="phone" placeholder="Phone Number"
								required onChange={handleChange} />
						</div>

						<div className="form-group">
							<label for="messsage" className="sm-hidden">Please write your enquiry</label>
							<textarea name="message" id="message" value = {formData.message} 
								 className="form-control" cols="30"
								 placeholder = "Message" style = {{marginTop: '2px'}}
								required onChange={handleChange}>
									Message
									</textarea>

						</div>
						<br />

						<button type="submit" className="btn btn-submit">
							Send Enquiry
						</button>

					</form>

				</div>

			</div>

		</section>

  );
};

export default Enquiry;
