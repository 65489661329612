import React from "react";
import jollyHockeyImage from '../images/gamesimage/JollyHockey/jollyhockey.jpg';  // Ensure this path is correct
import { useNavigate } from "react-router-dom";

const JollyHockey = () => {
  const navigatePrevious = useNavigate();
  function navigateToPreviousGame() {
    navigatePrevious("/InfernoBrawl"); 
  }

  const navigateNext = useNavigate();
  function navigateToNextGame() {
    navigateNext("/JollyFootball");
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={navigateToPreviousGame} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={jollyHockeyImage} className="d-block w-100" alt="Jolly Hockey" />
                </div>
                {/* You can add more carousel items here */}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Jolly Hockey</h1>
          <p>
            Welcome to Jolly Hockey, where you can enjoy an exciting range of hockey games and challenges. Dive into the fun and explore all that we have to offer!
            Step onto the ice and embrace the thrill of the rink with Jolly Hockey! 
            This exhilarating game combines the fast-paced action of hockey with engaging gameplay mechanics,
             offering a truly immersive experience for hockey fans and gamers alike
          </p>
          <button onClick={navigateToNextGame} className="btn btn-secondary">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default JollyHockey;
