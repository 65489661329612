import React from "react";
import venturexImage from '../images/gamesimage/Venturex/venturex.jpg'; 
import { useNavigate } from "react-router-dom";

const Venturex = () => {
  const navigate = useNavigate();

  function handlePrevious() {
    navigate('/ChessKnight'); 
  }

  function handleNext() {
    navigate('/Detonix'); // Replace with the path for the next game
  }

  return (
    <div className="container my-5 w-75">
      <button onClick={handlePrevious} className="btn btn-secondary mb-5">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous Game</span>
      </button>
      <div className="row align-items-center casino-card">
        <div className="col-md-6 text-center">
          <div className="card carousel-card">
            <div
              id="carouselExampleControlsVenturex"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={venturexImage}
                    className="d-block w-100 img-fluid"
                    style={{ width: '350px', height: '350px' }} 
                    alt="Venturex"
                  />
                </div>
                {/* Add more carousel items here if needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h1>Venturex</h1>
          <p>
           
            Welcome to Venturex, where the thrill of the game meets the excitement of winning. Our casino offers a wide range of games and entertainment for all types of players. Join us for an unforgettable experience.
            Step into the world of Venturex, where adventure and strategy collide in an epic gaming experience! Prepare to embark on a journey filled with thrilling challenges, strategic depth, and endless excitement.
          </p>
          <div className="d-flex justify-content-between mt-3">
            
            <button
              onClick={handleNext}
              className="btn btn-secondary"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venturex;
